/* Lato */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/Lato-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/Lato-Regular.ttf') format('truetype');
}

/* Singolare Layers */
@font-face {
  font-family: 'SingolareLayers';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/Singolare-Layers-Black.ttf') format('truetype');
}
