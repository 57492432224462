:root {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
html {
  scroll-behavior: smooth !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
